import Axios from 'axios';
import Vue from "vue";
import moment from 'moment';
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { data } from 'flickity';

export default {

  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      hiring: {
        company: {},
        user: {},
        files: [],
        reviews: [],
      },
      myPivot: {},
      isPaymentDate: false,
      errors: {
        financial: false,
      },
      banks: [],
      bankInput: '',
      selectedBank: '',
      form: {
        legal: {},
        financial: {
          type: 'pj',
        },
      },
      hasReviewedFlag: false,
    }
  },

  mounted() {

    var _this = this;

    if (_this.$store.state.role == 'admin') _this.$router.push({ path: '/dashboard/admin' });
    if (_this.$store.state.role == 'client') _this.$router.push({ path: '/dashboard/client' });

    axios
      .get('/api/v1/hirings/' + _this.$route.params.id, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        // console.log(response.data);
        if (!response.data.error) {
          console.log(response.data.data);
          _this.hiring = response.data.data;
          _this.myPivot = _this.hiring.providers.find(function (element) {
            return element.id == _this.me.id;
          }).pivot;
        } else {
          console.log(response.data.data);
          // logout and send to login page
          _this.$store.commit('setToken', '');
          _this.$store.commit('setMe', '');
          _this.$router.push('/?openlogin=true&briefing=' + _this.$route.params.id);
        }
      });


    axios
      .get('/api/v1/users/' + this.me.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.form = response.data.data;
          this.selectedBank = this.form.financial.bank_id;
        }
      });

    axios
      .get('/api/v1/banks', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.banks = response.data.data;
          let index = this.banks.map(function (e) { return e.id; }).indexOf(this.form.financial.bank_id);
          if (index > -1) this.bankInput = this.banks[index].name;
        }
      });

    _this.isPaymentDate = moment() > moment(_this.hiring.date_start).add(1, 'd');
  },

  computed: {
    projectName() {
      return '#' + this.hiring.id + '-' + moment(this.hiring.created_at).format('Y');
    },

    isCompleted() {
      if (this.hiring.status != 4) return false;
      if (moment(this.hiring.date_end) >= moment()) return false;
      // if (!this.hiring.invoices || !this.hiring.invoices.length) return false;
      // this.hiring.invoices.forEach(invoice => {
      //   if (invoice.status != 2) return false;
      // });
      return true;
    },

    canEvaluate() {
      if (this.hiring.status != 4) return false;
      if (moment(this.hiring.date_end) >= moment()) return false;
      if (this.haveReviewed) return false;
      return true;
    },

    haveReviewed() {
      if (!this.hiring.reviews) return false;
      let haveReviewed = this.hiring.reviews.map((e) => e.user_id).indexOf(this.me.id);
      if (haveReviewed > -1) return true;
      if (this.hasReviewedFlag) return true;
      return false;
    },

    reviewDate() {
      if (!this.haveReviewed) return moment();
      let review = {};
      let index = this.hiring.reviews.map((e) => { return e.user_id }).indexOf(this.me.id);
      if (index > -1) review = this.hiring.reviews[index];
      return review.created_at;
    },
  },

  methods: {
    openToast() {
      this.$toast.open({
        message: `<p class="white">${this.$t(
          "dash.evaluation_error"
        )}</p>`,
        position: "bottom-left",
        duration: 3000,
      });
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    toCurrency(val) {
      let value = val * 1;
      let currency = "BRL";
      if (this.me.currency == "eur") {
        value = val / this.$store.state.currencies.eur;
        currency = "EUR";
      }
      if (this.me.currency == "usd") {
        value = val / this.$store.state.currencies.usd;
        currency = "USD";
      }
      return (value / 100).toLocaleString("pt-BR", { style: "currency", currency: currency, minimumFractionDigits: 2 });
    },

    valueByPlan(budget, plan) {
      let value = 0;
      switch (plan) {
        case 1:
          value = budget * 0.85;
          break;
        case 2:
          value = budget * 0.85;
          break;
        case 3:
          value = budget * 0.75;
          break;
        case 4:
          value = budget * 0.8928571429;
          break;
        case 5:
          value = budget;
          break;
        case 6:
          value = budget * 0.70;
          break;
        case 7:
          value = budget * 0.80;
          break;
        default:
          value = budget * 0.85;
      }
      return value;
    },

    sendProposal(formData) {
      let fail = false;
      let financial = this.form.financial;
      let value = parseInt(formData.value.replace(/\D/g, ''));

      // check if user has financial data
      if (formData.decision != 3) {
        if (financial.razao == '' ||
          !financial.razao ||
          financial.agency == '' ||
          !financial.agency ||
          financial.account == '' ||
          !financial.account) {
          fail = true;
        }
        if (financial.account && financial.account.indexOf('@') > 0) {
          fail = true;
          alert('Seus dados estão incorretos, verifique agência e conta.');
        }
        if (fail) {
          $('#financial-account-modal').modal('show');
          return;
        }
      }

      if (this.me.currency == 'eur') {
        value = value * this.$store.state.currencies.eur;
      }
      if (this.me.currency == 'usd') {
        value = value * this.$store.state.currencies.usd;
      }

      // enviar proposta
      var data = {
        decision: formData.decision,
        workvalue: value,
      };
      axios
        .put('/api/v1/hirings/' + this.hiring.id + '/send-proposal', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    changeProposal() {
      this.myPivot.status = 1;
    },

    giveupContract() {
      var data = {};
      axios
        .put('/api/v1/hirings/' + this.hiring.id + '/giveup-contract', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            $('#cancel-contract-modal').modal('hide');
            this.$router.push({ path: '/dashboard/professional/' });
          } else {
            console.log(response.data.error);
          }
        });
    },

    evaluate(formData) {
      var data = formData;
      try {
        axios
          .post('/api/v1/reviews?hiring=' + this.hiring.id, data, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            if (response.data.error) {
              this.openToast()
              console.log(response.data.error);
            }
          });
        this.hiring.reviews = [{ ...data, user_id: this.me.id }]
        this.hasReviewedFlag = true
      } catch (err) {
        this.openToast()
        console.log(err)
      }

    },

    sendInvoice(form) {
      let formData = new FormData();
      formData.append('invoice', form.file);
      formData.set('name', 'NF - Creator');
      // formData.set('due_date', form.date);
      // formData.set('value', parseInt(form.value.replace(/\D/g, '')));
      formData.set('hiring', form.hiring_id);
      axios
        .post('/api/v1/invoices', formData, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          if (!response.data.error) {
            // hiring = response.data.data;
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    setBankId(e) {
      let index = this.banks.map(function (e) { return e.name; }).indexOf(this.bankInput);
      if (index > -1) this.selectedBank = this.banks[index].id;
      // console.log(this.selectedBank);
    },

    submitFinancial() {
      let _this = this;
      let data = {
      };
      let form = _this.form;
      // Dados bancários
      data.cnpj = form.legal.cnpj;
      data.bank = _this.selectedBank;
      data.type = form.financial.type;
      data.razao = form.financial.razao;
      data.agency = form.financial.agency;
      data.account = form.financial.account;
      axios
        .put('/api/v1/users/' + _this.me.id, data, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            _this.financial.errors = response.data.error;
          } else {
            $('#financial-account-modal').modal('hide');
          }
        });
    },

    editHiring(data) {
      axios
        .put('/api/v1/hirings/' + data.id, data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
          } else {
            console.log(response.data.error);
          }
        });
    },


  }

}
